.assembly-info-drawer {
    .MuiDrawer-paper {
        width: 700px;
    }

    .page-title {
        padding: 20px 16px;
    }

    .field-container {
        padding: 0 16px 16px;
    }

    .info-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
    }       
}