@import '../../../../css/variables.scss';

.report-menu {
    .MuiMenu-paper {
        margin-top: 20px;
    }

    .report-menu-container {
        display: flex;
        flex-direction: column;

        .MuiButton-root {
            width: 100%;
            justify-content: flex-start !important;
        }

        .MuiButton-root + .MuiButton-root {
            margin-left: 0;
        }
    }   
}