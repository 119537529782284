@import "../../../css/variables.scss";

.action-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 6px;
    background-color: $paperBg;
    border-radius: 4px;

    .MuiDivider-root {
        margin-left: 0px;
    }

    .MuiIconButton-root {
        margin: 0 6px;
    }

    .unit-icon {
        padding-left: 6px;
    }

    .polarity-button {
        margin-right: 6px;
    }

    .assembly-edit-button {
        margin: 0 6px;
    }
}