@import '../../../css/variables.scss';

.fiber-mapping-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        min-width: 880px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }
    
    .subheader-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .mapping-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 16px 0;
        padding: 16px 16px 0;
        height: 264px;
        background-color: $paperBg;
        border-radius: 4px;
        user-select: none;
        border: 1px solid $primary-border;

        .selection-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .selection-dropdown + .selection-dropdown {
                margin-left: 8px;
            }
        }

        .select {
            width: 120px;
            margin-right: 8px;

            .MuiInputBase-root {
                height: 36px;

                .MuiInputBase-input {
                    color: $primary-main;
                }
            }
        }

        .connector-container {
            height: 100%;
        }

        .connector-pin {
            cursor: pointer;

            &:hover {
                .connector-pin-highlight {
                    fill: $backgroundDisabled;
                    fill-opacity: 1;
                }
            }

            &.disabled {
                cursor: unset;

                .connector-pin-highlight {
                    fill-opacity: 0;
                }
            }

            &.selected {
                .connector-pin-highlight {
                    fill: $primary-light;
                    fill-opacity: 1;
                }
            }
        }
    }

    .mapping-action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}