@import "../../../css/variables.scss";

.drawer-row {
    .MuiIconButton-root {
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .assembly-name {
        transition: color 0.3s ease;
    }

    &:hover {
        .MuiIconButton-root {
            opacity: 1;
        }
        .assembly-name {
            color: $primary-main;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .row-middle {
        padding-left: 16px;
        width: 100%;

        .assembly-info {
            .assembly {
                &-name {
                    padding-bottom: 18px;
                }
            }
        }
    }

    .row-end {
        .assembly-info-toolbar {
            display: flex;
            flex-direction: row;
            padding-bottom: 8px;

            .MuiIconButton-root + .MuiIconButton-root {
                margin-left: 6px;
            }           
        }
    }
}