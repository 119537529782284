.top-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    white-space: nowrap;

    .left-panel {
        flex: 0 0 0;
        display: flex;
        flex-direction: row;
        margin-left: 16px;

        .status-indicator {
            margin-left: 12px;
        }
    }
    
    .fiber-counter {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .action-panel {
        margin-right: 16px;
    }
}