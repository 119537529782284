@import "../css/variables.scss";


.login-container {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    background-image: url("./resources/image_login_background.jpg");

    .login-header {
        height: 175px;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(20px);

        .corning-glass-logo {
            padding: 16px;
            height: 143px;
        }
    }

    .login-footer {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        bottom: 0px;
        height: 50px;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(20px);
    }

    .login-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 140px;
        
        .cab-title {
            margin-bottom: 16px;
            color: $secondary-main;
        }
    
        .login {
            width: 300px;
            padding: 16px;
            background-color: $paperBg;
            border-radius: 4px;
    
            .title-container {
                display: flex;
                flex-direction: column;
                padding-bottom: 16px;
            }
    
            .login-fields {
                display: flex;
                flex-direction: column;
                height: 128px;

                .MuiFormControl-root {
                    width: 100%;
                }
    
                .orbit-text-field-container + .orbit-text-field-container {
                    margin-top: 16px;
                }

                .MuiOutlinedInput-root {
                    padding-right: 0;
                }

                input::-ms-reveal, input::-ms-clear {
                    display: none !important;
                }
            }
    
            .login-error-message {
                margin: 8px 0;
                color: $error-main;
            }
    
            .login-button {
                height: 48px;
                width: 100%;
                border-radius: 24px;
            }
        }
    }
}