@import "../../../css/variables.scss";

.connector-assignment-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        min-width: 850px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
        max-height: 800px;
    }

    .subheader {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -10px;
        justify-content: space-between;

        .MuiFormControlLabel-root {
            margin-bottom: 6px;
        }

        .propagation-button {
            margin-left: auto;
        }
        .polarity-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .select {
                width: 200px;
                margin-left: 8px;
                margin-right: 8px;

                .MuiInputBase-root {
                    height: 36px;

                    .MuiInputBase-input {
                        color: $primary-main;
                    }
                }
            }
            .show-polarity-button {
                margin-right: 8px;
                border: 1px solid $primary-border;
                border-radius: 4px;
            }
        }
    }
    
    .assignment-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .local-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            &.side-a {
                margin-right: 8px;
            }

            &.side-b {
                margin-left: 8px;
            }

            .container-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 6px 6px;
                margin-bottom: 8px;
                background-color: $backgroundDisabled;

                .container-title {
                    width: 100%;
                    text-transform: uppercase;
                    text-align: center;
                }
            }

            .table-container {
                max-height: 482px;
                overflow-y: auto;
                border: 1px solid $primary-border;
                border-radius: 4px;
            }

            .assignment-table {
                background-color: $paperBg;
                border-collapse: unset;
                height: 482px;
                
                &.side-a {
                    .MuiTableCell-root {
                        &.fiber-indicator {
                            padding-right: 0;
                        }
                    }
                }

                .MuiTableRow-root {
                    display: flex;
                    border: none;

                    .MuiIconButton-root {
                        visibility: hidden;
                    }

                    &:hover {
                        background-color: $primary-background;
                    }

                    &.selected {
                        background-color: $primary-light;

                        .MuiIconButton-root {
                            visibility: hidden;
                        }

                        .MuiTableCell-root {
                            &.assignment-icon {
                                padding: 0 12px !important;

                                .row-selection-icon {
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    &.assigned {
                        .assignment-icon {
                            padding: 0 6px !important;
                        }

                        &:hover {
                            .MuiIconButton-root {
                                visibility: visible;
                            }
                        }

                        .MuiTableCell-root {
                            .assigned-icon {
                                visibility: visible;
                            }
                        }
                    }

                    .MuiTableCell-root {
                        display: flex;
                        align-items: center;
                        height: 48px;
                        padding: 0 12px;
                        user-select: none;
                        border: none;

                        &.assignment-icon {
                            .MuiIconButton-root {
                                pointer-events: none;
                            }

                            i {
                                visibility: hidden;
                            }

                            .row-selection-icon {
                                visibility: hidden;
                            }
                        }

                        &.connector-info {
                            padding-left: 0;
                            padding-right: 0;
                            width: 100%;
                        }

                        &.delete-button {
                            padding-left: 0;
                            padding-right: 6px;
                        }

                        &.edit-button {
                            padding-left: 0;
                            padding-right: 6px;
                        }

                        &.propagation-icon {
                            padding-left: 0;
                            padding-right: 6px;
                            
                            & .link-icon {
                                visibility: visible;
                            }

                            & .link-icon:hover {
                                background-color: transparent !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .assignment-action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 8px;

    }
}