@import '../../../css/variables.scss';

.details-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;
    padding: 4px 12px 4px 6px;
    background-color: $paperBg;
    border-radius: 4px;

    .MuiDivider-root {
        margin-left: 6px;
    }

    .tnc-button {
        height: 24px;

        &:hover {
            text-decoration-line: underline;
        }
    }

    .details {
        padding-left: 12px;
        user-select: none;
    }
}