@import '../../../../css/variables.scss';

.breakout-details-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        min-width: 1096px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
        max-height: 800px;
    }

    .MuiDivider-root {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .table-container {
        max-height: 650px;
        overflow-y: auto;
    }

    .breakout-details-table {
        padding-bottom: 16px;

        .MuiIconButton-root {
            margin-left: 4px;
        }

        .table-header-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            button {
                margin-left: 4px;
            }
        }

        .MuiTableBody-root {
            .MuiTableRow-root {
                padding-top: 4px;
            }
        }

        .MuiTableCell-root {
            border-bottom: 1px solid $divider;

            &.MuiTableCell-head {
                padding-top: 8px;
                padding-bottom: 8px;
                padding-left: 0;
                padding-right: 20px;
                background-color: $paperBg;

                &.total-length {
                    padding-right: 16px;
                }

                &.leg-label {
                    padding-left: 16px;
                }

                &.breakout-length-label {
                    width: 60px;
                }
            }

            &.MuiTableCell-body {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 2px;
                padding-right: 24px;

                &.leg-checkbox {
                    padding-left: 3px;
                }
            }
        }

        .editable-cell {
            &.leg-length {
                .editable-field {
                    width: 108px;
                    height: 50px;
                }
            }
            &.leg-label {
                .editable-field {
                    width: 202px;
                    height: 50px;
                }
            }
        }
    }

    .navigation-bar {
        position: relative;
        width: 100%;
    }
}

.color-dialog {        
    &.breakout-details-connector {
        top: 200px !important;
        right: 530px !important;
    }

    &.breakout-details-label {
        top: 200px !important;
        right: 125px !important;
    }
    .MuiDialog-container > div {
        width: 100%;
    }
}