$thesansc4s: 'TheSansC4s';

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-ExtraLight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-ExtraLightItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-SemiLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-SemiLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-Plain.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-PlainItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: $thesansc4s;
    src: url('./resources/TheSansC4s-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}