@import "../../css/variables.scss";

.help-dialog {
    .help-container {
        width: fit-content;
        display: flex;
        flex-direction: row;

        .interactions-container {
            padding-right: 16px;

            .interaction {
                .MuiListItem-root {
                    :hover {
                        background-color: $primary-background;
                    }
                    .MuiButtonBase-root {
                        :hover {
                            background-color: unset;
                        }
                    }
                }
    
                &.selected {
                    background-color: $primary-light;
                }
            }
        }

        .animations-container {
            width: 1280px;
            height: 720px;
            background-color: white;
    
            .MuiTypography-root {
                position: relative;
                top: 10px;
                left: 10px;
            }
        }
    
    }

    .MuiPaper-root {
        min-width: fit-content;
        margin: 0;
    }
}