@import "../../../css/variables.scss";

.drawer-sub-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    background-color: $paperBg2;
    box-shadow: 0px 2px 6px $backgroundDisabled;
    padding: 10px 16px;

    .sort-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .sort-label {
            margin-right: 4px;
        }

        .sort-order-icon {
            margin: 0 8px;
            padding-left: 6px;
            border: 1px solid $primary-border;
        }

        .select {
            height: 36px;
            width: 250px;

            .MuiInputBase-root {
                height: 36px;
                text-transform: uppercase;

                .MuiInputBase-input {
                    color: $primary-main;
                }
            }
        }
    }
}