@import '../../../css/variables.scss';

.print-dialog {
    .MuiPaper-root {
        width: 530px;
        min-width: 530px;
        height: 595px;
        min-height: 595px;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }

    .print-container {
        display: flex;
        flex-direction: column;

        .MuiDivider-root {
            margin-bottom: 12px;
        }

        .field-container {
            margin-top: 16px;
            padding-bottom: 8px;

            &.horizontal {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                input[value=""]:not(:focus) {
                    color: transparent;
                }

                .orbit-text-field-container {
                    width: 48%;
                }
            }
        }

        .checkbox-fields {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
        }

        .image-upload {
            .MuiTypography-root {
                margin-top: 16px;
            }

            .file-selector {
                margin: 16px 0;
            }
            
            .filename-chips {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                gap: 8px;
                height: 80px;                
                overflow-y: scroll;
            }
        }
    }

    .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        bottom: 15px;
        right: 15px;

        .print-progress {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .MuiCircularProgress-root {
            color: $secondary-main;
            width: 20px !important;
            height: 20px !important;
        }
    }
}