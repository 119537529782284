@import '../../../css/variables.scss';

.fiber-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    background-color: $paperBg;
    border-radius: 4px;
    user-select: none;

    .side-a {
        margin-right: 4px;
        color: $success-main;

        &.error {
            color: $error-main;
        }
    }

    .assembly {
        margin: 0 4px;
        color: $success-main;
    }

    .side-b {
        margin-left: 4px;
        color: $success-main;

        &.error {
            color: $error-main;
        }
    }
}