@import '../../../css/variables.scss';

.polarity-toolbar {
    position: fixed;
    margin-top: 16px;
    right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: $paperBg;

    .progress-container {
        display: flex;
        flex-direction: column;

        .assigned-fibers {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 6px;

            .title {
                text-transform: uppercase;
            }
        }

        .progress-bar {
            box-sizing: content-box;
            width: 150px;
            height: 8px;
            border-radius: 25px;
            background-color: $backgroundDisabled;

            span {
                display: block;
                height: 100%;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                background-color: $success-main;
            }

            &.exceeded {
                span {
                    background-color: $error-main;
                }
            }
        }
    }

    .MuiDivider-root {
        margin-left: 8px;
        margin-right: 2px;
    }

    .polarity-scheme-button {
        &.active {
            background-color: $primary-background;
        }
    }

    .connector-assignment-button {
        &.active {
            background-color: $primary-background;
        }
    }

    .close-icon {
        margin-left: 6px;
    }
}