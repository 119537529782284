@import '../../../../css/variables.scss';

.user-menu {
    .MuiMenu-paper {
        margin-top: 20px;
    }

    .MuiButton-root {
        width: 100%;
        justify-content: flex-start !important;
    }
}