#pixi-container {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    #pixi-stage {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
}