.breakout-edit-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        min-width: 425px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }

    .breakout-attributes {
        display: flex;
        flex-direction: column;
    }

    .legs-attributes {
        display: flex;
        flex-direction: column;
    }

    .field-container {
		padding-bottom: 16px;

		&.horizontal {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

            .field {
                width: 48%;
            }
		}
    }

    .color-picker {
        .MuiInputBase-root {
            user-select: none;
            cursor: default;

            .MuiInputBase-input {
                pointer-events: none;
                user-select: none;
            }
        }
        
    }

    .select {
        width: 100%;
    }

    .MuiDialogActions-root {
        padding-top: 0 !important;
    }
}

.color-dialog {
    &.breakout-jacket {
        top: 235px !important;
        right: 220px !important;
    }

    &.leg-jacket {
        top: 415px !important;
        right: 220px !important;
    }
}