@import '../../../css/variables.scss';

.editable-cell {
    margin-top: 4px;

    .orbit-text-field-container {
        .MuiInputBase-root {
            height: 48px;
            margin-top: 5px;
            padding-left: 16px;
            color: $primary-main;

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }

            &:hover {
                .MuiIconButton-root {
                    pointer-events: none;
                }

                .MuiSvgIcon-root {
                    display: initial;
                }
            }

            &.Mui-focused {
                color: $text-primary;
                border: 1px solid $primary-border;
                border-radius: 4px;

                .MuiSvgIcon-root {
                    display: none;
                }
            }

            &.Mui-error {
                color: $error-main;
                border: 1px solid $error-main;
                border-radius: 4px;
            }

            .MuiSvgIcon-root {
                display: none;
            }
        }

        .max-length {
            display: none;
            visibility: hidden;
        }
    }
}