@import "../../../css/variables.scss";

.drawer-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px;

    .corning-logo {
        height: 32px;
        
        img {
            width: 218px;
            height: 32px;
        }
    }
}