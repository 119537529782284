.wizard-header {
    margin-bottom: 8px;

    .top-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title-container {
            display: flex;
            flex-direction: column;
        }

        .close-container {
            margin-top: 5px;
        }
    }
}