@import '~@orbit/icon-button/build/index.css';
@import '~@orbit/text-field/build/index.css';
@import "../css//variables.scss";

.workspace {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    top: 0px;
    left: 0px;
    position: fixed;

    .non-selectable {
        user-select: none;
    }

    label {
        user-select: none;
    }

    .left-float {
        float: left;
    }

    .right-float {
        float: right;
    }

    .flex-vertical-container {
        display: flex;
        flex-direction: column;
    }
    
    .tite-container {
        position: fixed;
        display: flex;
        flex-direction: column;
        text-align: center;

        top: 30%;
        left: 38%;

        label {
            color: #0098DB;
        }

        label:first-child {
            font-size: 150px;
        }

        label:last-child {
            font-size: large;
        }
    }
}

.dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.MuiDialog-root {
    border: 1px solid $primary-border !important;
    border-radius: 4px !important;
}

.MuiDialogTitle-root {
    padding: 16px 16px !important;
}

.MuiDialogContent-root {
    padding: 16px 16px !important;
}

.MuiDialogActions-root {
    padding: 16px 16px !important;
}

.MuiTabs-root {
    .MuiTab-root {
        font-family: $thesansc4s;
        font-weight: 700;
        letter-spacing: 0.45px;

        &.Mui-selected {
            color: $primary-main;
        }
    }

    .MuiTabs-indicator {
        background-color: $primary-main;
    }
}

.MuiMenu-paper {
    background-color: $paperBg !important;
    border: 1px solid $primary-border;

    .MuiMenu-list {
        .MuiMenuItem-root {
            color: $primary-main;
        }
    }
}

.MuiFormControlLabel-root {
    &.selected {
        color: $primary-main;
    }
}

.MuiButton-root + .MuiButton-root {
    margin-left: 6px;
}

.MuiFormControl-root {
    &.select {
        width: 100%;
        
        .MuiFormLabel-root {
            padding-top: 20px;
        }
    }
}

.MuiAutocomplete-popper {
    .MuiPaper-root {
        border-radius: 0;
        background-color: $paperBg;
    }

    .MuiAutocomplete-paper {
        margin: 0;
    }

    .MuiAutocomplete-listbox {
        padding-top: 0;
        padding-bottom: 0;
    }

    .MuiAutocomplete-option {
        color: $primary-main;
    }
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        background-color: black;
        font: $typography-tooltip;
    }

    .MuiTooltip-arrow {
        color: black;
    }
}

.MuiDivider-root {
    background-color: $divider;
}

.MuiPopover-root {
    .MuiPaper-root {
        max-height: 132px;
    }
    
    .MuiList-root {
        padding: 0;
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $primary-main;
    border-radius: 4px;
}

//Firefox scrollbar CSS properties
* {
    scrollbar-width: thin;
    scrollbar-color: $primary-main transparent;
}