@import "../../../../css/variables.scss";

.propagation-dialog {
    & .connectorAttributes-container {
       display: flex;
       flex-direction: column; 
    }

    & .buttons-container {
        display: flex;
        justify-content: flex-end;
    }

    & .MuiDialogContent-root {
        padding-top:  0 !important;
    }
}