@import '../../css/variables.scss';

.tnc-dialog {
    .MuiPaper-root {
        width: 830px;
        min-width: 830px;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }

    .tnc-container {
        display: flex;
        flex-direction: column;

        .text-area-container {
            padding: 8px 16px 16px;
            margin: 8px 0 16px;
            height: 400px;
            user-select: none;
            overflow: auto;
            white-space: pre-line;
            background: white !important;
            border: 1px solid $primary-border;
            border-radius: 4px;
            font: $typography-body2;

            .text-area-header {
                text-align: center;
            }
        }

        .actions-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .tnc-accepted {
                margin-right: 16px;
            }
        }
    }
}