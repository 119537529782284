@import "../../css/variables.scss";

.bottom-bar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    bottom: 16px;
    white-space: nowrap;

    .left-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 16px;
    }

    .toolbar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;

        .breakout-toolbar-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            padding: 0 6px;
            background-color: $paperBg;
            border-radius: 4px;

            .MuiIconButton-root + .MuiIconButton-root {
                margin-left: 6px;
            }
        }
    }
    
    .units-display {
        margin-left: 12px;
    }

    .right-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px;
    }
}