.settings-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        margin: 0;
    }

    .MuiDialogContent-root {
        padding: 0 0 16px !important;
    }

    .drawing-specs {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
    }

    .polarity-report {
        display: flex;
        flex-direction: column;
        padding: 8px 16px;

        .sort-options {
            display: flex;
            flex-direction: row;
        }

        .MuiFormGroup-root {
            margin-left: 10px;
        }
    }
}