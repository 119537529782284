@import '../../css/variables.scss';

.annotation-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        min-width: 1096px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
        max-height: 600px;
    }
    
    .annotation-table {
        max-height: 400px;
        padding-bottom: 16px;
        overflow-y: auto;

        .MuiTableCell-root {
            &.MuiTableCell-head {
                padding: 0 16px;
                height: 48px;
                background-color: $paperBg;

                &.description-column {
                    width: 100%;
                }
            }

            &.MuiTableCell-body {
                padding: 0 16px;
                height: 48px;

                &.description-column {
                    width: 100%;
                    color: $primary-main;
                }
            }
        }
    }

    .notes-section {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        height: 100%;

        .notes-area {
            max-height: 400px;

            .MuiTextField-root {
                height: 380px;
                max-height: 400px;

                .MuiInputBase-root {
                    height: 100%;
                    max-height: 400px;
                    padding: 0;

                    textarea {
                        height: 100% !important;
                        overflow: auto !important;
                        max-height: 400px;
                        padding-left: 16px;
                    }
                }
            }
        }

        .dialog-buttons-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            padding-top: 16px;
        }
    }
}