@import '../../../css/variables.scss';

.polarity-schemes-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        min-width: 1100px;
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }

    .dialog-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        .polarity-button-container {
            display: flex;
            flex-direction: row;
            overflow: auto;
            margin-bottom: 16px;

            .MuiButton-root {
                min-width: unset;
                white-space: nowrap;
                margin-bottom: 2px;
            }
        }

        .polarity-scheme-container {
            display: flex;
            flex-direction: row;
            height: 100%;

            .polarity-schemes-list {
                display: flex;
                flex-direction: column;
                margin-right: 16px;
            }

            .polarity-diagram-container {
                display: flex;
                flex-direction: column;
                background-color: white;
                border: 1px solid $primary-border;
                width: 100%;
                height: 100%;

                .polarity-name {
                    padding: 16px;
                    background-color: $backgroundDisabled;
                }

                .polarity-diagram {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    vertical-align: middle;
                    height: 100%;
                    max-height: 100%;
                    padding: 4px 16px;
                    overflow-y: auto;

                    img {
                        max-width: 100%;
                        max-height: 430px;
                    }
                }

                .polarity-reference {
                    width: 100px;
                    margin: 8px;
                    padding: 6px 16px;
                    background-color: $backgroundDisabled;
                }

                .polarity-no-diagram {
                    padding: 16px 16px;
                }

                .instruction-container {
                    display: flex;
                    flex-direction: column;
                    padding: 16px 16px;

                    .instruction-title {
                        padding-bottom: 16px;
                    }
    
                    .instructions {
                        padding-bottom: 16px;
                    }
                }

                .polarity-unavailable {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 16px 16px;

                    .MuiIconButton-root {
                        margin-right: 6px;
                        pointer-events: none;
                    }

                    .unavailable-message {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}