#polarity-diagram-tables-container {
    td {
        min-height: 430px;
    }

    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        height: 100%;
        max-height: 100%;
        padding: 4px 16px;
        overflow-y: auto;

        img {
            max-width: 100%;
            max-height: 430px;
        }
    }
}