@import "../../../css/variables.scss";

.fit-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    margin-left: 16px;
    padding: 0 6px;
    background-color: $paperBg;
}

.fit-menu-container {
    display: flex;
    flex-direction: column;

    .MuiButton-root {
        padding: 10px 20px;
    }

    .MuiButton-root + .MuiButton-root {
        margin-left: 0;
    }
}