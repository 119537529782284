@import './font/fonts.scss';

$primary-main: #005293;
$primary-dark: #003056;
$primary-light: #99CCFF;
$primary-background: #00529314;
$primary-border: #00529380;
$primary-contrastText: #FFFFFF;

$secondary-main: #F5F5F5;
$secondary-dark: #EAEAEA;
$secondary-light: #FFFFFF;
$secondary-background: #FFFFFF14;
$secondary-border: #FFFFFF80;
$secondary-contrastText: #005293;

$success-main: #009B3A;
$success-dark: #006C28;
$success-light: #33AF61;
$success-textDark: #001F0C;
$success-background: #009B3A14;
$success-border: #009B3A80;
$success-contrastText: #FFFFFF;

$error-main: #C60C30;

$text-primary: #4E4E50E5;
$text-secondary: #0000008A;
$text-disabled: #00000061;

$divider: #E0E0E0;
$paperBg: #E1F5FC;
$paperBg2: #F3FCFF;
$backgroundDisabled: #0000001F;

$typography-body2: 14px $thesansc4s;
$typography-tooltip: 10px $thesansc4s;