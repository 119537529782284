@import '../../../../css/variables.scss';

.connector-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .MuiIconButton-root {
        border: 1px solid $primary-border !important;
    }
}