@import '../../../css/variables.scss';

.assembly-edit-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }

    .MuiDialogActions-root {
        padding-top: 0 !important;
    }

    .field-container {
        align-items: center;
        padding-bottom: 16px;
    }

    .max-length {
        margin-bottom: 6px;
    }

    .fields-wrapper {
        display: flex;
    
        .tolerances-container {
            display: flex;
            flex-direction: column;
            width: 340.45px;
            margin-left: 32px;

            .leg-length-tolerances, .leg-prime-length-tolerances, .leg-label-length-tolerances {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;

                .fields-container {
                    display: flex;
                    margin-top: 8px;
                }
            }

            .tolerance-separator {
                display: flex;
                align-items: center;
                height: 53px;
                margin-left: 5px;
                margin-right: 5px;
                color: black;
                opacity: 0.8;
                font-size: 16px;
                font-family: Arial;  
            }

            .field-helper-text-container .MuiFormHelperText-root {
                margin-left: 0;
                margin-right: 0;
                font-size: 10px;
                white-space: nowrap;
                letter-spacing: 0
            }
        }
    }
}

.color-dialog {
    &.assembly-jacket {
        top: 305px !important;
        right: 289px !important;
    }
}