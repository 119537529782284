@import "../../../css/variables.scss";

.card-container {
	position: fixed;
	display: flex;
	z-index: 1300;
	max-height: calc(100% - 144px); // 100% - (topBar + bottomBar + margins)
	width: 400px;
	top: 80px;
	right: 16px;
	overflow-y: auto;
	background-color: $paperBg;
	border-radius: 4px;
}

.cable-setup {
	padding: 16px 16px 0px 16px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;

	.field-container {
		padding-bottom: 8px;

		&.with-hint {
			.MuiTypography-root {
				padding-top: 4px;
				padding-left: 16px;
			}
		}

		&.horizontal {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.field {
                width: 48%;
            }

			.multiply {
				font-family: Arial, Helvetica, sans-serif;
				margin-top: auto;
				margin-bottom: auto;
				margin-left: 5px;
				margin-right: 5px;
			}
		}
	}

	.color-picker {
        .MuiInputBase-root {
            user-select: none;
            cursor: default;

            .MuiInputBase-input {
                pointer-events: none;
                user-select: none;
            }
        }
    }

	.MuiFormGroup-root {
		margin-left: 10px;
	}

	.group-input {
		margin-bottom: 8px;
	}

	.step-hint {
		margin-bottom: 16px;
	}

	.sub-title {
		.MuiDivider-root {
			margin-bottom: 16px;
		}
	}

	.add-breakouts-button {
		width: 100%;
	}

	.wizard-footer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		margin-bottom: 20px;

		&.flex {
			justify-content: space-between;
		}

		.wizard-footer-right {
			.finish-button {
				height: 48px;
				border-radius: 24px !important;
			}
		}
	}
	.label-help-shift { // MUI labels seem to have 16px margin on the right side, this shifts the help icon into that space.
		margin-left: -16px;
		margin-right: 16px;
	}
}

.color-dialog {
	width: 256px;

	&.general-setup-jacket {
		top: 700px !important;
		right: 145px !important;	
	}

	&.breakout-setup-jacket {
		top: 505px !important;
		right: 145px !important;
	}

	&.breakout-setup-prime-jacket {
		top: 570px !important;
		right: 145px !important;
	}
}