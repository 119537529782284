@import '../../../../css/variables.scss';

.polarity-schemes-list {
    width: 500px;

    .polarity-schemes-row-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: $primary-background;
        }

        .lock-icon {
            pointer-events: none;
        }

        .MuiTypography-root {
            width: 100%;
        }
    }
}