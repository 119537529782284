.unit-dialog {
    top: 80px !important;
    right: 16px !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        margin: 0;
    }

    .MuiDialogContent-root {
        padding: 0 0 16px !important;
    }

    .unit-container {
        padding: 0 16px;

        .radio-container {
            display: flex;
            flex-direction: column;

            + .radio-container {
                margin-left: 32px;
            }

            .radio-hint {
                margin-left: 32px;
            }
        }
    }

    .MuiDivider-root {
        margin: 8px 0;
    }
}