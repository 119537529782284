@import "../../../css/variables.scss";

.generic-autocomplete {
    height: 56px;
    
    .MuiAutocomplete-root {
        height: 56px;
    }

    .MuiInputBase-root {
        .MuiInputBase-input {
            cursor: pointer;
        }
    }

    &.invalid {
        padding-bottom: 16px;

        .MuiFormLabel-root {
            color: $error-main;
        }

        .MuiFormHelperText-root {
            padding-top: 0px;
            color: $error-main;
        }
    }

    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }

    .MuiAutocomplete-endAdornment {
        right: 4px !important;

        .MuiIconButton-root {
            &:hover {
                background-color: transparent;
            }
        }
    }
}