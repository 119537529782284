@import "../../../css/variables.scss";

.project-manager-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    padding-left: 10px;
    padding-right: 16px;
    background-color: $paperBg;
    border-radius: 4px;
    user-select: none;

    .logo {
        height: 16px;
        padding-left: 10px;
        padding-right: 12px;
    }

    .cab-title {
        padding-right: 12px;
        color: $primary-main;
        user-select: none;
    }

    .cable-name {
        padding-left: 12px;
    }

    .part-number {
        padding-top: 1px;
        padding-left: 12px;
        color: $primary-main;
    }
}