.status-indicator {
    display: flex;
    align-items: center;
    
    .status-container {
        display: flex;
        flex-direction: column;
        border-radius: 5px 5px 5px 5px;
        height: 48px;
        width: 350px;

        .MuiLinearProgress-root {
            border-radius: 0px 0px 10px 10px;
        }
    
        .status-label {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 8px;

            .MuiIconButton-root {
                pointer-events: none;
            }

            .MuiTypography-root {
                margin-left: 4px;
                margin-right: 16px;
            }
        }
    
    }
}