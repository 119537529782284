@import '../../../css/variables.scss';

.help-icon {
    color: $primary-main;
    cursor: help;
}

.help-icon-container {
    font-size: 1lh;
    vertical-align: middle;
}