@import '../../../css/variables.scss';

.color-dialog {
    top: unset !important;
    right: unset !important;
    left: unset !important;
    bottom: unset !important;

    .MuiPaper-root {
        margin: 0;
    }

    .MuiDialogContent-root {
        padding-top: 0 !important;
    }

    .MuiDialogActions-root {
        padding-top: 0 !important;
    }

    .color-palette {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        padding: 0 0 8px;
        max-width: 340px;

        .color-button {
            margin-left: 8px;
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }
}