@import "../../../css/variables.scss";

.scale-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    padding-left: 6px;
    padding-right: 16px;
    background-color: $paperBg;
    border-radius: 4px;
    
    .zoom-in-icon {
        margin-right: 6px;
    }

    .zoom-out-icon {
        margin-left: 6px;
    }

    .MuiDivider-root {
        margin-left: 6px;
        margin-right: 32px;
    }

    .scale-percentage {
        width: 64px;
        white-space: nowrap;
    }
}