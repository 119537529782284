@import "../../../css/variables.scss";

.drawer-row {
    align-items: center;
    padding: 20px 16px;

    &:hover {
        background: $primary-background;

        .build-toolbar {
            opacity: 1.0;
        }

        .drag-button {
            opacity: 1.0;
        }
    }

    &.selected {
        background: $primary-light;

        .build-toolbar {
            opacity: 1.0;
        }

        .drag-button {
            opacity: 1.0;
        }
    }

    .row-middle {
        width: 55%;
    }

    .row-end {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
}